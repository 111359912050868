import Jsona, { SwitchCaseJsonMapper } from 'jsona'
import { snakeKeys } from 'change-object-case'
import { client } from './client'
import { toSnake, toCamel } from 'change-object-case'

const deserializer = new Jsona({
  jsonPropertiesMapper: new SwitchCaseJsonMapper({ switchChar: '_' }),
})

async function getReports(params) {
  const response = await client().get('v2/ds6/nobo/reports', { params: toSnake(params) })

  return {
    data: deserializer.deserialize(response),
    meta: toCamel(response.meta),
  }
}

async function getReviews(params) {
  const response = await client().get('v2/ds6/nobo/unparsed', { params: toSnake(params) })

  return {
    data: deserializer.deserialize(response),
    meta: toCamel(response.meta),
  }
}

async function getReview(id) {
  const response = await client().get(`v2/ds6/nobo/unparsed/${id}`)

  return deserializer.deserialize(response)
}

async function lockReview(review) {
  try {
    await client().post(review.links.lock)
    return true
  } catch (error) {
    return false
  }
}

async function unlockReview(review) {
  try {
    await client().delete(review.links.lock)
    return true
  } catch (error) {
    return false
  }
}

async function updateReview(review, nobo) {
  const params = snakeKeys(nobo)

  // snake keys doesn't quite get this attribute right
  params.street2 = params.street_2
  delete params.street_2

  try {
    const response = await client().put(review.links.self, params)
    return response.success
  } catch (err) {
    return false
  }
}

async function getCustomerSecurities(customerId) {
  const response = await client().get('/v2/ds6/nobo/securities', {
    params: { customer_id: customerId },
  })
  return deserializer.deserialize(response)
}

async function createCustomerSecurity({ customerId, securityId, cusip, user, notifyUsers }) {
  const response = await client().post('/v2/ds6/nobo/securities', {
    customer_id: customerId,
    security_id: securityId,
    cusip: cusip,
    user_id: user,
    notify_user_ids: notifyUsers,
  })
  return deserializer.deserialize(response)
}

async function updateCustomerSecurity({ id, customerId, securityId, cusip, user, notifyUsers }) {
  const response = await client().put(`/v2/ds6/nobo/securities/${id}`, {
    customer_id: customerId,
    security_id: securityId,
    cusip: cusip,
    user_id: user,
    notify_user_ids: notifyUsers,
  })
  return deserializer.deserialize(response)
}

export {
  getReports,
  getReviews,
  getReview,
  lockReview,
  unlockReview,
  updateReview,
  getCustomerSecurities,
  createCustomerSecurity,
  updateCustomerSecurity,
}
