import { useState } from 'react'
import { useQuery } from 'react-query'
import usePagination from 'hooks/usePagination'
import { searchPeopleAdmin } from 'services/people'
import { searchOrganizationsAdmin } from 'services/organizations'

const SERVICES = {
  people: searchPeopleAdmin,
  organizations: searchOrganizationsAdmin,
}

const useIDSearchData = ({ entity, params: otherParams }) => {
  const { pagination, handlePageChange, handleRowsChange, pageSizeOptions } = usePagination()

  const [selectedCustomer, setSelectedCustomer] = useState(null)

  const [filters, setFilters] = useState({})
  const params = {
    term: filters.term,
    pageSize: pagination.pageSize,
    pageOffset: pagination.pageOffset,
    customerId: selectedCustomer?.id,
    ...otherParams,
  }

  const handleFilter = ({ target: { value } }) => setFilters({ term: value || undefined })

  const termPresent = Boolean(params.term)

  const getRequest = SERVICES[entity]
  const queryKey = ['idSearchQuery', entity, params]
  const { data, isFetching } = useQuery({
    queryKey,
    queryFn: () => getRequest(params),
    initialData: [],
    enabled: termPresent,
  })

  return {
    data,
    termPresent,
    pagination,
    isFetching,
    handleFilter,
    handlePageChange,
    handleRowsChange,
    pageSizeOptions,
    selectedCustomer,
    setSelectedCustomer,
  }
}

export default useIDSearchData
