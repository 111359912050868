import { useState, useRef, useCallback, useEffect } from 'react'
import { useMutation, useQuery } from 'react-query'
import Rollbar from 'utils/rollbar'
import {
  getPresignedUrl,
  getFileUploadStatus,
  uploadFileWithPresignedUrl,
} from './FileUploadServices'

const FILE_STATUS = {
  IN_PROGRESS: 'in_progress',
  COMPLETED: 'completed',
  THREATS_FOUND: 'threats_found',
  FAILED: 'failed',
}

/**
 * Custom hook to handle file uploads with presigned URLs and virus scanning
 * @param {object} props - props object
 * @param {Function} props.onFileUploadSuccess - callback function to handle successful file upload
 * @param {string} props.bucketType - type of bucket to upload file to
 * @returns {object} An object containing:
 *   @returns {object} uploadInfo - Information about the current file upload
 *   @returns {string} uploadFileType - Type of file being uploaded
 *   @returns {boolean} isUploading - Whether a file is currently being uploaded
 *   @returns {Function} uploadFile - Function to initiate file upload
 *   @returns {Function} setUploadFileType - Function to set the upload file type
 *   @returns {Function} handleCancel - Function to cancel an ongoing upload
 */
const useSecureFileUpload = ({ onFileUploadSuccess, bucketType }) => {
  const [uploadInfo, setUploadInfo] = useState(null) // File info fileKey and fileName
  const [uploadFileType, setUploadFileType] = useState(null) // Keep track of whether it's an image or file, or for Logo upload left of right logo

  const previousStatus = useRef({}) // To track previous file status and avoid unnecessary re-renders
  const abortController = useRef(null) // Abort controller to cancel ongoing requests

  // Mutation for S3 upload with presigned URL
  const uploadToS3 = useMutation(
    ({ presignedUrl, file }) => {
      abortController.current = new AbortController()
      return uploadFileWithPresignedUrl({
        presignedUrl,
        file,
        signal: abortController.current.signal,
      })
    },
    {
      onError: (_, variables) => {
        const { file } = variables
        const fileName = file.name
        setUploadInfo(prevState => {
          return {
            ...prevState,
            [fileName]: { ...prevState[fileName], error: 'Failed to upload file' },
          }
        })
      },
      onSuccess: (_, variables) => {
        const { file, fileKey } = variables
        const fileName = file.name
        setUploadInfo(prevState => {
          return {
            ...prevState,
            [fileName]: { fileKey, fileName },
          }
        })
      },
    },
  )

  // Mutation to get presigned URL
  const uploadMutation = useMutation(
    files => {
      abortController.current = new AbortController()
      return getPresignedUrl(files, bucketType, abortController.current.signal)
    },
    {
      onSuccess: async (presignedArray, files) => {
        // Upload file to S3 using presigned URL
        // This is being handled on the client side to avoid having to transfer
        // the file to the server first, so it saves bandwidth and time
        await Promise.all(
          files.map((file, index) => {
            return uploadToS3.mutateAsync({
              presignedUrl: presignedArray[index].presignedUrl,
              fileKey: presignedArray[index].fileKey,
              file,
            })
          }),
        )
      },
      onError: (error, files) => {
        setUploadInfo(
          files.reduce((acc, file) => {
            acc[file.name] = {
              fileName: file.name,
              error: 'Failed to get presigned URL',
            }
            return acc
          }, {}),
        )
      },
    },
  )

  // Reset all upload states
  const resetAllUploadState = useCallback(() => {
    uploadMutation.reset() // Reset presigned URL mutation
    uploadToS3.reset() // Reset S3 upload mutation
    setUploadInfo(null)
    setUploadFileType(null)
    previousStatus.current = {} // Reset previousStatus for the next upload
  }, [uploadMutation, uploadToS3])

  // Helper function to check file upload statuses and trigger updates
  const processFileStatuses = useCallback(
    (fileUploadStatus, previousStatus) => {
      // Return early if no status data
      if (!fileUploadStatus?.length) {
        return null
      }

      // Convert array to lookup object
      const statuses = fileUploadStatus.reduce((acc, { status, fileKey, url }) => {
        if (fileKey) {
          acc[fileKey] = { status, url }
        }
        return acc
      }, {})

      // Skip if no changes from previous status
      const hasStatusChanged = Object.keys(statuses).some(
        fileKey => statuses[fileKey].status !== previousStatus.current?.[fileKey]?.status,
      )
      if (!hasStatusChanged) {
        return null
      }

      // Check if all complete and schedule reset
      const allComplete = fileUploadStatus.every(({ status }) => status === FILE_STATUS.COMPLETED)
      if (allComplete) {
        setTimeout(resetAllUploadState, 3000)
      }

      return statuses
    },
    [resetAllUploadState],
  )

  // Query to check file status
  const { data: fileUploadStatus } = useQuery(
    ['fileUploadStatus', ...Object.keys(uploadInfo || {})],
    () => {
      abortController.current = new AbortController()
      return getFileUploadStatus(
        Object.values(uploadInfo).map(item => item.fileKey),
        bucketType,
      )
    },
    {
      // caching is disabled as we don't want to cache the file status
      cacheTime: 0,
      staleTime: 0,
      // Some files may still be in the process of getting their fileKey from the presigned URL request and we don't need to wait for all of them
      enabled: !!(uploadInfo && Object.values(uploadInfo).some(info => info.fileKey)),
      retry: 10, // max 20 seconds
      refetchInterval: data => {
        // Note when upgrading to @tanstack/react-query v5 - https://tanstack.com/query/latest/docs/framework/react/guides/migrating-to-v5#the-refetchinterval-callback-function-only-gets-query-passed

        if (!data || !data.some(({ status }) => status === FILE_STATUS.IN_PROGRESS)) {
          return false // Stop polling after we get a final statuses
        }
        return 2000
      },
      onError: () => {
        // Mark remaining uploads as failed after retries are exhausted
        Object.keys(uploadInfo).forEach(fileName => {
          if (!uploadInfo[fileName].isSuccess && !uploadInfo[fileName].error) {
            setUploadInfo(prevState => ({
              ...prevState,
              [fileName]: { ...prevState[fileName], error: 'Upload timeout - please try again' },
            }))
          }
        })
        resetAllUploadState()
      },
    },
  )

  // Handle status updates after file upload
  useEffect(() => {
    const newStatuses = processFileStatuses(fileUploadStatus, previousStatus)
    if (!newStatuses) {
      return
    }

    previousStatus.current = newStatuses

    // Update status for each file...
    Object.entries(newStatuses).forEach(([fileKey, { status, url }]) => {
      const fileInfo = Object.values(uploadInfo).find(info => info.fileKey === fileKey)
      if (!fileInfo) {
        return
      }

      const { fileName } = fileInfo
      const updateInfo = {
        [fileName]: {
          ...uploadInfo[fileName],
          ...(status === FILE_STATUS.COMPLETED && {
            isSuccess: true,
          }),
          ...(status === FILE_STATUS.THREATS_FOUND && {
            error: 'This file may contain a virus or other malware and cannot be uploaded.',
          }),
          ...(status === FILE_STATUS.FAILED && {
            error: 'File upload failed',
          }),
        },
      }

      setUploadInfo(prev => ({ ...prev, ...updateInfo }))

      if (status === FILE_STATUS.COMPLETED) {
        onFileUploadSuccess({ url, fileName }, uploadFileType)
      }
    })
  }, [fileUploadStatus, uploadInfo, uploadFileType, onFileUploadSuccess, processFileStatuses])

  // Handle file upload initiation
  const handleFileUpload = files => {
    // Reset any previous upload state first
    previousStatus.current = {}

    setUploadInfo(
      files.reduce((acc, file) => {
        acc[file.name] = { fileName: file.name }
        return acc
      }, {}),
    )
    uploadMutation.mutate(files)
  }

  // Handle cancel upload
  const handleCancel = async () => {
    try {
      // First abort any in-progress requests
      if (abortController.current) {
        await abortController.current.abort()
      }

      // Update status to cancelled for each file before resetting
      if (uploadInfo) {
        const cancelledState = Object.keys(uploadInfo).reduce(
          (acc, fileName) => ({
            ...acc,
            [fileName]: {
              ...uploadInfo[fileName],
              error: 'Upload cancelled',
            },
          }),
          {},
        )
        setUploadInfo(cancelledState)
      }

      // Reset state after a short delay to allow UI to show cancelled status
      setTimeout(() => {
        resetAllUploadState()
        // Clear the abort controller to ensure it's ready for new uploads
        abortController.current = null
      }, 1000)
    } catch (error) {
      console.warn('Error aborting upload:', error)
      Rollbar.error('Error aborting upload:', error)
      resetAllUploadState()
    }
  }

  return {
    uploadFile: handleFileUpload,
    // This is used to show the UploadStatusModal, this modal needs to be shown during whole process and we close this modal after 3 seconds of successful upload or kept open in case of error, or user cancel. UploadInfo has been set at the start of the upload process and reset until resetAllUploadState is called, which is called after 3 seconds of successful upload or user cancel(close).
    isUploading: !!uploadInfo,
    uploadInfo,
    handleCancel,
    uploadFileType,
    setUploadFileType,
  }
}

export default useSecureFileUpload
