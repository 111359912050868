import React from 'react'

import { AttachFile as AttachFileIcon } from '@the-platform-group/component-library/Icons'
import { Button } from '@the-platform-group/component-library'

import { useModal } from 'hooks/useModal'

import FileUploadModal from './FileUploadModal'

/**
 * A button component that allows users to upload NOBO files.
 *
 * This component provides a file upload interface with the following features:
 * - Restricts uploads to CSV files only
 * - Uses secure file upload to a private S3 bucket
 * - Shows a status modal during file upload
 * - Supports multiple file selection
 *
 * @returns {React.Element} A button component with file upload functionality
 */
export default function FileUploadModalOpenButton() {
  const { open, handleOpen, handleClose } = useModal()
  return (
    <>
      <Button
        variant="outlined"
        component="label"
        aria-label="close"
        size="small"
        startIcon={<AttachFileIcon />}
        onClick={() => handleOpen()}
      >
        Upload NOBO File
      </Button>
      <FileUploadModal open={open} handleClose={() => handleClose()} />
    </>
  )
}
