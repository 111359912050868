import React from 'react'
import { irwin } from 'config'
import useCustomerAffiliations from '../hooks/useCustomerAffiliations'
import useSearchInputRead from '../hooks/useSearchInputRead'
import { Grid } from '@the-platform-group/component-library/Layout'
import { CustomLink as Link } from '@the-platform-group/component-library'
import { useFileDownload } from 'hooks/useFileDownload'
import SharedTable from './SharedTable'
import { ExportButton } from '@the-platform-group/component-library/Button'
import { getCustomerAffiliationsCSVUrl } from 'services/customerAffiliations'

export const emptyMessage =
  "Please enter an affiliated ID for a system person or organization. If the affiliated entity is a custom person or organization, please go to person's profile in that customer's account."

const AffiliationCell = React.memo(({ value }) => (
  <Link to={{ pathname: value.url }} target="_blank">
    {value.content}
  </Link>
))

const columns = [
  { field: 'customer', flex: 0.25 },
  {
    field: 'affiliation',
    renderCell: params => <AffiliationCell {...params} />,
  },
]

const buildUrlAndContent = (source, affiliatedType) => {
  const { id, type } = source || { id: 'unknown', type: 'unknown' }
  switch (type) {
    case 'activity':
      return {
        url: `${irwin.appUrl}/activities/${id}`,
        content: `${source.type} (${source.date}) - ${source.title}`,
      }
    case 'tag':
      return {
        url:
          affiliatedType === 'person'
            ? `${irwin.appUrl}/irm/people?tags=${id}`
            : `${irwin.appUrl}/irm/organizations?tags=${id}`,
        content: `${source.type} - ${source.name}`,
      }
    case 'customContact':
      return {
        url: `${irwin.appUrl}/people/${id}`,
        content: `Custom Contact - ${source.firstName} ${source.lastName}`,
      }
    case 'unknown':
      return {
        url: '',
        content: 'Unknown (deleted affiliation)',
      }
    default:
      throw new Error(`Unsupported source type: ${type}`)
  }
}

const getRowData = affiliation => {
  const { id, customer, source, affiliatedType } = affiliation
  return {
    id,
    customer: customer.name,
    affiliation: buildUrlAndContent(source, affiliatedType),
  }
}

function AffiliationsTable() {
  const { affiliatedId } = useSearchInputRead()
  const customerAffiliation = useCustomerAffiliations(affiliatedId)
  const props = {
    columns,
    emptyMessage,
    getRowData,
    useHook: customerAffiliation,
    name: 'affiliationsTable',
  }
  const { download, isLoading: isDownloading } = useFileDownload()

  const handleExportCSV = () => {
    const csvUrl = getCustomerAffiliationsCSVUrl(affiliatedId)
    download(csvUrl)
  }

  return (
    <>
      {affiliatedId && (
        <Grid item container xs={12} justifyContent="flex-end">
          <Grid item>
            <ExportButton
              isLoading={isDownloading}
              handleClick={handleExportCSV}
              disabled={
                customerAffiliation.state.isLoading ||
                customerAffiliation.state.failed ||
                !customerAffiliation.state.total
              }
              options={[
                {
                  onClick: handleExportCSV,
                },
              ]}
            />
          </Grid>
        </Grid>
      )}
      <SharedTable rows={customerAffiliation.state.affiliations} {...props} />
    </>
  )
}

export default AffiliationsTable
