import React from 'react'
import PropTypes from 'prop-types'
import { AsyncSelect } from '@the-platform-group/component-library'
// Services
import { getOrganizations } from 'services/organizations'
import { isEmpty } from '@the-platform-group/formatters/checks'

const OrganizationSelect = props => {
  const { label, onChange, organization } = props

  const value = isEmpty(organization) ? null : organization
  return (
    <AsyncSelect
      hideArrow
      id={organization.id}
      label={label}
      variant="outlined"
      optionsLoader={fetchOrganizations}
      onChange={onChange}
      value={value}
      getOptionLabel={({ option: org }) => (org?.id ? `${org.name} ${org.type}` : '')}
      getOptionSelected={(option, selected) => option.id === selected.id}
      fullWidth
    />
  )
}

OrganizationSelect.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  organization: PropTypes.object.isRequired,
}
OrganizationSelect.defaultProps = {
  label: '(unnamed)',
}

async function fetchOrganizations(term) {
  if (!term) {
    return { options: [] }
  }

  const response = await getOrganizations(0, 15, term)
  return response.data
}

export default OrganizationSelect
