import { useState } from 'react'
import { getReviews } from 'services/nobo'
import { useQuery } from 'react-query'

const initialSortState = {
  field: 'created_at',
  direction: 'asc',
}

const initialPaginationState = {
  rowsPerPage: 50,
  page: 0,
}

function useReviews() {
  const [filterState, setFilterState] = useState({ customerId: '' })
  const [sortState, setSortState] = useState(initialSortState)
  const [paginationState, setPaginationState] = useState(initialPaginationState)

  function toggleSort(field) {
    let newDirection = 'desc'

    if (field === sortState.field) {
      newDirection = sortState.direction === 'asc' ? 'desc' : 'asc'
    }

    setSortState({ field: field, direction: newDirection })
  }

  function setCustomerFilter(option) {
    const value = option ? option.value : null
    setFilterState({ ...filterState, customerId: value })
  }

  function setPage(number) {
    setPaginationState({ ...paginationState, page: number })
  }

  function setRowsPerPage(number) {
    setPaginationState({ ...paginationState, rowsPerPage: number })
  }

  const { isFetching, data: reviews } = useQuery({
    queryKey: ['noboReviews', sortState, paginationState, filterState],
    queryFn: () =>
      getReviews({
        sort: `${sortState.field}_${sortState.direction}`,
        pageSize: paginationState.rowsPerPage,
        pageOffset: paginationState.page * paginationState.rowsPerPage,
        ...filterState,
      }),
    initialData: { data: [], meta: {} },
  })

  const customerOptions = []
  reviews.data.forEach(review => {
    const option = { label: review.customer.name, value: review.customer.id }
    const exists = customerOptions.find(o => o.label === option.label && o.value === option.value)

    if (!exists) {
      customerOptions.push(option)
    }
  })

  const { page, rowsPerPage } = paginationState

  return {
    filterState,
    reviews: reviews.data,
    sortState,
    toggleSort,
    customerOptions,
    setCustomerFilter,
    isLoading: isFetching,
    rowCount: reviews.meta?.count || 0,
    page,
    rowsPerPage,
    setPage,
    setRowsPerPage,
  }
}

export default useReviews
