import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { toSnake } from 'change-object-case'

import { AttachFile as AttachFileIcon } from '@the-platform-group/component-library/Icons'
import {
  Button,
  FormControl,
  Grid,
  Select,
  Typography,
} from '@the-platform-group/component-library'
import { useNotification } from '@the-platform-group/component-library/Notification'
import Modal from 'components/Modal'

import useSecureFileUpload from './useSecureFileUpload'
import { sendNoboFileToQueue } from './FileUploadServices'
import FileUploadStatusModal from './FileUploadStatusModal'

const ALLOWED_EXTENSIONS = ['.csv']
const PRIVATE_S3_BUCKET_TYPE = 'private'

/**
 * Modal component for uploading NOBO files.
 *
 * This component provides the following features:
 * - File upload interface with secure S3 upload functionality
 * - Country selection for the uploaded file
 * - Validation of file extensions
 * - Submission of uploaded files for processing
 * - Error handling and notifications
 *
 * @param {boolean} open - Controls whether the modal is displayed (defaults to false)
 * @param {Function} handleClose - Function called when the modal is closed
 * @returns {React.ReactElement} The rendered FileUploadModal component
 */
export default function FileUploadModal({ open = false, handleClose }) {
  const [uploadedFile, setUploadedFile] = useState({})
  const [selectedCountry, setSelectedCountry] = useState('us')
  const [formErrors, setFormErrors] = useState([])
  const { createNotification } = useNotification()

  const secureUpload = useSecureFileUpload({
    onFileUploadSuccess: ({ fileName, url }) => {
      setUploadedFile({
        fileName,
        fileKey: url.replace(/^https:\/\/[^/]+\//, ''),
      })
      return
    },
    bucketType: PRIVATE_S3_BUCKET_TYPE,
  })

  const isSecureFileUploading = secureUpload?.isUploading //

  const handleSecureFileUpload = ({ target: { files, name } }) => {
    secureUpload.setUploadFileType(name)
    secureUpload.uploadFile(files)
  }

  const handleCountryChange = event => {
    setSelectedCountry(event.target.value)
  }

  const { isLoading, mutate: submitUploadForProcessing } = useMutation({
    mutationFn: async () => {
      // Secure upload: use the uploaded file URLs
      const data = toSnake({
        path: uploadedFile.fileKey,
        countryCode: selectedCountry,
      })
      return await sendNoboFileToQueue(data)
    },
    onSuccess: () => {
      createNotification({
        message: 'Your NOBO file has been submitted for processing',
        variant: 'success',
      })
      handleClose()
    },
    onError: e => {
      const errors = e.response.data?.errors
      if (errors) {
        setFormErrors(prev => prev.concat(errors))
      } else {
        createNotification({
          message: 'There was an issue with the upload. Please try again later.',
          variant: 'error',
        })
      }
    },
  })

  useEffect(() => {
    // reset things after we close the modal
    if (!open) {
      setUploadedFile({})
      setSelectedCountry('us')
      setFormErrors([])
    }
  }, [open])

  return (
    <Modal
      open={open}
      title="NOBO File Upload"
      saveButtonText="Process"
      handleClose={handleClose}
      sx={{ alignItems: 'flex-start', '.footerContainer': { justifyContent: 'flex-start' } }}
      footer={
        <Button
          variant="outlined"
          component="label"
          aria-label="close"
          size="small"
          startIcon={<AttachFileIcon />}
        >
          Choose NOBO File
          <input
            name="nobo-file"
            type="file"
            // Convert FileList to an array so we can use array methods
            onChange={event =>
              handleSecureFileUpload({ target: { files: [...event.target.files] } })
            }
            style={{ display: 'none' }}
            value=""
            accept={ALLOWED_EXTENSIONS}
          />
        </Button>
      }
      isLoading={isLoading || isSecureFileUploading}
      handleSave={submitUploadForProcessing}
      {...(isSecureFileUploading && {
        beforeTitle: <FileUploadStatusModal files={secureUpload?.uploadInfo} />,
      })}
    >
      {uploadedFile.fileName && (
        <FormControl fullWidth>
          {formErrors?.map((error, idx) => (
            <Typography key={idx} variant="caption" paragraph color="error.main" sx={{ my: 0.25 }}>
              {error.message}
            </Typography>
          ))}
          <Grid container direction="row" justifyContent="space-evenly" alignItems="center">
            <Grid item xs={6}>
              {uploadedFile.fileName}
            </Grid>
            <Grid item xs={6}>
              <Select
                sx={{
                  minWidth: 110,
                }}
                required
                id="country-code"
                value={selectedCountry}
                onChange={handleCountryChange}
                label="Country Code"
                options={[
                  { value: 'us', label: 'us' },
                  { value: 'ca', label: 'ca' },
                ]}
              ></Select>
            </Grid>
          </Grid>
        </FormControl>
      )}
    </Modal>
  )
}
