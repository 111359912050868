import ORGANIZATION_TYPES from 'constants/organizationTypes'
import capitalize from 'lodash/capitalize'
import { Link } from 'react-router-dom'
import { datasources } from 'pages/ReportingGrants/transforms/datasources'
import { irwin } from 'config'

const getOrganizationColumns = () => [
  { field: 'id', headerName: 'Internal ID' },
  { field: 'legacyId', headerName: 'Factset ID' },
  { field: 'name' },
  { field: 'domain' },
  {
    field: 'type',
    valueFormatter: ({ value }) => ORGANIZATION_TYPES[value] || capitalize(value),
  },
  { field: 'datasource', valueFormatter: ({ value }) => datasources[value] },
]

const getPeopleColumns = () => [
  { field: 'id', headerName: 'Internal ID' },
  { field: 'legacyId', headerName: 'Factset ID' },
  { field: 'firstName' },
  { field: 'lastName' },
  {
    field: 'organization',
    renderCell: ({ row }) => {
      const jobs = row.jobs || []
      const primaryJob = jobs.find(job => job.isPrimary) || jobs[0]
      const primaryOrg = primaryJob?.organization || {}
      const { id, name } = primaryOrg
      return (
        <Link to={{ pathname: `${irwin.appUrl}/organizations/${id}` }} target="_blank">
          {name}
        </Link>
      )
    },
  },
  { field: 'datasource', valueFormatter: ({ value }) => datasources[value] },
]

const getTableDataConfig = entity => {
  const configs = {
    people: getPeopleColumns(),
    organizations: getOrganizationColumns(),
  }
  return configs[entity]
}

export { getTableDataConfig }
