import React from 'react'
import { Grid, Divider } from '@the-platform-group/component-library'
import { Button } from '@the-platform-group/component-library/Button'
import {
  Modal as ModalComponent,
  ModalTitle,
  ModalContent,
  ModalActions,
} from '@the-platform-group/component-library/Modal'

/**
 * A reusable modal component with customizable header, content, and footer.
 *
 * This component provides the following features:
 * - Customizable title and content
 * - Optional save and close buttons with customizable text
 * - Flexible footer content with adjustable positioning
 * - Optional content to display before the title
 *
 * @param {boolean} open - Controls whether the modal is displayed
 * @param {Function} handleClose - Function called when the modal is closed
 * @param {Function} handleSave - Function called when the save button is clicked
 * @param {string} title - The title displayed in the modal header
 * @param {React.ReactNode} children - The content to display in the modal body
 * @param {string} saveButtonText - Custom text for the save button (defaults to "Save")
 * @param {React.ReactNode} footer - Optional content to display in the footer
 * @param {React.ReactNode} beforeTitle - Optional content to display before the title
 * @param {Object} rest - Additional props passed to the underlying Modal component
 * @returns {React.ReactElement} The rendered Modal component
 */
const Modal = ({
  open,
  handleClose,
  handleSave,
  title,
  children,
  saveButtonText,
  footer,
  footerJustifyContent = 'flex-end', // allow flexibility in use for footer item positioning
  isLoading,
  beforeTitle,
  ...rest
}) => {
  return (
    <ModalComponent open={open} onClose={handleClose} {...rest}>
      {beforeTitle}
      {title && <ModalTitle title={title} />}
      <ModalContent>{children}</ModalContent>
      <ModalActions>
        <Grid container direction="column" spacing={2}>
          {footer && (
            <Grid item xs>
              <Divider />
            </Grid>
          )}
          <Grid item container spacing={2} alignItems="center">
            <Grid item container className="footerContainer" justifyContent="flex-end" xs={8}>
              <Grid item>{footer}</Grid>
            </Grid>
            <Grid item container justifyContent="flex-end" spacing={1} xs={4}>
              {handleClose && (
                <Grid item>
                  <Button disabled={isLoading} variant="text" onClick={handleClose}>
                    Cancel
                  </Button>
                </Grid>
              )}
              {handleSave && (
                <Grid item>
                  <Button isLoading={isLoading} onClick={handleSave}>
                    {saveButtonText || 'Save'}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </ModalActions>
    </ModalComponent>
  )
}

export default Modal
