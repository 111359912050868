import React from 'react'

// imports
import { DataGrid } from '@the-platform-group/component-library/DataGrid'
import { Grid } from '@the-platform-group/component-library/Layout'
import { Typography } from '@the-platform-group/component-library'
import { ListPaper } from 'components/Paper'
import { AsyncSelect } from '@the-platform-group/component-library/Select'
import FileUploadModalOpenButton from './components/FileUploadModalOpenButton'

// hooks
import useReports from './hooks/useReports'
import { debouncedLoadCustomers } from 'utils/optionsLoaders'
import { getColumnValues, getRowValues } from './ReportTableValues'

/**
 * Component for displaying NOBO reports in a data grid with filtering capabilities.
 *
 * This component provides the following features:
 * - Displays NOBO reports in a paginated data grid
 * - Allows filtering reports by customer name
 * - Includes file upload functionality when enabled via feature flag
 * - Supports pagination and adjustable page size
 *
 * @returns {React.ReactElement} The rendered NOBO reports component
 */
const ReportListComponent = () => {
  const {
    reports,
    isLoading,
    pagination,
    filters,
    handlePageChange,
    handleRowsChange,
    handleCustomerSearch,
  } = useReports()

  const { page, itemCount, pageSize } = pagination
  const optionsLoader = term => debouncedLoadCustomers({ term })

  return (
    <>
      <Typography variant="h3">NOBO Reports</Typography>
      <Grid container direction="column" spacing={2}>
        <Grid item container alignItems="center">
          <Grid item xs={6}>
            <FileUploadModalOpenButton />
          </Grid>
          <Grid item justifyContent="right" xs={6}>
            <AsyncSelect
              id="customersFilter"
              placeholder="Search customers"
              value={filters.customerSearch}
              onChange={handleCustomerSearch}
              optionsLoader={optionsLoader}
              hideArrow
              includeClearAll
            />
          </Grid>
        </Grid>
        <Grid item>
          <ListPaper>
            <DataGrid
              columns={getColumnValues()}
              commonColumnProps={{ align: 'center', headerAlign: 'center' }}
              rows={getRowValues(reports)}
              loading={isLoading}
              localeText={{
                noRowsLabel:
                  'Looks like nothing matches your search. Try removing some criteria or attempt a new search.',
              }}
              name="noboReports"
              page={page}
              pageSize={pageSize}
              onPageChange={handlePageChange}
              onPageSizeChange={handleRowsChange}
              rowCount={itemCount}
              pageSizeOptions={[10, 25, 50, 100]}
            />
          </ListPaper>
        </Grid>
      </Grid>
    </>
  )
}

export default ReportListComponent
