import React, { useMemo } from 'react'
import useIDSearchData from './hooks/useIDSearchData'
import { debouncedLoadCustomers } from 'utils/optionsLoaders'

// Components
import { DataGrid } from '@the-platform-group/component-library/DataGrid'
import { TextField, Typography, Grid, AsyncSelect } from '@the-platform-group/component-library'
import { getTableDataConfig } from './hooks/utils'

const IDSearchTable = ({ entity }) => {
  const {
    data: responseData,
    termPresent,
    pagination,
    isFetching,
    handleFilter,
    handlePageChange,
    handleRowsChange,
    pageSizeOptions,
    selectedCustomer,
    setSelectedCustomer,
  } = useIDSearchData({ entity })
  const { page, pageSize } = pagination

  const columns = getTableDataConfig(entity)

  const optionsLoader = useMemo(() => {
    return term => debouncedLoadCustomers({ term })
  }, [])

  const noRowsLabel = termPresent
    ? 'Looks like nothing matches your search. Try removing some criteria or attempt a new search.'
    : `Search to display ${entity}`

  return (
    <>
      <Typography variant="h5" gutterBottom fontWeight="fontWeightBold" textTransform="capitalize">
        ID Search {entity}
      </Typography>
      <Grid item container alignItems="center" spacing={1}>
        <Grid item xs={6}>
          <TextField
            onChange={handleFilter}
            name="search"
            label={`Search ID Search ${entity}`}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <AsyncSelect
            includeClearAll
            id="customerId"
            label="Search Customer"
            variant="outlined"
            value={selectedCustomer}
            onChange={setSelectedCustomer}
            optionsLoader={optionsLoader}
            margin="none"
          />
        </Grid>
      </Grid>
      <DataGrid
        rows={responseData?.data || []}
        columns={columns}
        loading={isFetching}
        localeText={{
          noRowsLabel,
        }}
        name={`idSearch${entity}`}
        pagination
        onPageChange={handlePageChange}
        onPageSizeChange={handleRowsChange}
        page={page}
        pageSize={pageSize}
        pageSizeOptions={pageSizeOptions}
        rowCount={responseData?.count || 0}
      />
    </>
  )
}

export default IDSearchTable
