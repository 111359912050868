import { useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import {
  Box,
  Button,
  ProfilePicture,
  AvatarContainer,
  Typography,
} from '@the-platform-group/component-library'
import {
  AccountCircle as AccountCircleIcon,
  Sync as ReloadIcon,
} from '@the-platform-group/component-library/Icons'
import { getPeopleProfilePictures, fetchLatestLinkedInProfile } from 'services/people'

const FetchLatestProfileButton = ({ disabled, onClick }) => (
  <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
    <Button variant="outlined" size="small" disabled={disabled} onClick={onClick}>
      <ReloadIcon fontSize="small" />
      &nbsp;Fetch Latest Photo
    </Button>
    <Typography variant="caption" color="text.secondary">
      Note: fetching may take a while
    </Typography>
  </Box>
)

const useProfilePicture = person => {
  const [shouldLoadProfilePicture, setShouldLoadProfilePicture] = useState(false)
  const [isFetchingLatestProfile, setIsFetchingLatestProfile] = useState(false)
  const cacheDuration = 1000 * 60 * 30 // 30 minutes
  const queryClient = useQueryClient()

  const {
    data: profilePicture,
    isFetching: isFetchingProfilePicture,
    refetch: refetchProfilePicture,
    error: profilePictureError,
  } = useQuery({
    queryKey: ['peopleProfilePicture', person.id],
    queryFn: () => getPeopleProfilePictures([person.id]),
    enabled: !!person.id && shouldLoadProfilePicture,
    retry: false,
    cacheTime: cacheDuration,
    staleTime: cacheDuration,
    useErrorBoundary: error => {
      const status = error?.response?.status
      return !(status === 429 || status === 503) // manually handle 429 and 503 errors, otherwise throw error to boundary
    },
  })

  const fetchLatestProfile = async () => {
    setIsFetchingLatestProfile(true)
    const linkedInProfile = await queryClient.fetchQuery({
      queryKey: ['linkedInProfile', person.id],
      queryFn: () => fetchLatestLinkedInProfile([person.id]),
      cacheTime: cacheDuration,
      staleTime: cacheDuration,
    })

    if (linkedInProfile) {
      // once we have fetched the latest profile, we need to fetch or refetch the profile picture and show it
      if (shouldLoadProfilePicture) {
        // if we are already showing the profile picture, we need to reset cache & refetch it
        queryClient.setQueryData(['peopleProfilePicture', person.id], linkedInProfile)
      } else {
        setShouldLoadProfilePicture(true)
      }
      setIsFetchingLatestProfile(false)
    }
  }

  return {
    profilePicture,
    isFetching: isFetchingProfilePicture || isFetchingLatestProfile,
    refetchProfilePicture,
    profilePictureError,
    shouldLoadProfilePicture,
    setShouldLoadProfilePicture,
    fetchLatestProfile,
  }
}

/**
 * A container component that fetches and displays a person's LinkedIn profile picture
 * @param {Object} props - Component props
 * @param {Object} props.person - Person object containing id, firstName and lastName
 * @param {string} props.person.id - Unique identifier for the person
 * @param {string} props.person.firstName - Person's first name
 * @param {string} props.person.lastName - Person's last name
 * @param {('default'|'small'|'medium'|'large')} [props.size='default'] - Size variant for the profile picture
 * @returns {React.Component|null} ProfilePicture component or null if no person.id
 */
function ProfilePictureContainer({ person = {}, size = 'default' }) {
  const {
    profilePicture,
    isFetching,
    refetchProfilePicture,
    profilePictureError,
    shouldLoadProfilePicture,
    setShouldLoadProfilePicture,
    fetchLatestProfile,
  } = useProfilePicture(person)

  if (!person.id) {
    return null
  }

  if (shouldLoadProfilePicture) {
    return (
      <>
        <ProfilePicture
          error={
            profilePictureError?.response?.status === 503 &&
            profilePictureError?.response?.data.errors[0].message
          }
          firstName={person.firstName}
          lastName={person.lastName}
          isLoading={isFetching}
          profilePictureSrc={profilePicture?.[0]?.url}
          reloadImage={refetchProfilePicture}
          size={size}
        />
        <FetchLatestProfileButton disabled={isFetching} onClick={fetchLatestProfile} />
      </>
    )
  }

  return (
    <>
      <AvatarContainer>
        <Button
          onClick={() => setShouldLoadProfilePicture(!shouldLoadProfilePicture)}
          variant="outlined"
          sx={{
            borderRadius: 20,
            flexDirection: 'column',
            fontSize: '0.7rem',
            width: '100%',
            height: '100%',
          }}
        >
          <AccountCircleIcon />
          Preview
        </Button>
      </AvatarContainer>
      <FetchLatestProfileButton disabled={isFetching} onClick={fetchLatestProfile} />
    </>
  )
}

export default ProfilePictureContainer
