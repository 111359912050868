import React from 'react'
import { Route, Switch } from 'react-router-dom'

import IDSearchTable from './IDSearchTable'

function IDSearchRouter() {
  return (
    <Switch>
      <Route exact path="/id_search/people" component={() => <IDSearchTable entity="people" />} />
      <Route
        exact
        path="/id_search/organizations"
        component={() => <IDSearchTable entity="organizations" />}
      />
    </Switch>
  )
}

export default IDSearchRouter
