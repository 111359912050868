import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { Select, Grid, Typography } from '@the-platform-group/component-library'
import { Button } from '@the-platform-group/component-library/Button'
import { useNotification } from 'components/Notification'
import { ORIENTATION_TYPES, COVERAGE_STYLES } from 'constants/coverageStyles'
import { getOrganization } from 'services/organizations'
import {
  getOrganizationCoverageOverride,
  saveOrganizationCoverageOverride,
  getOrganizationCoverageAuditLog,
} from 'services/organizations'
import InternalUpdateLogList from 'components/InternalUpdateLogs'

const QUERY_KEYS = {
  coverage: 'organizationCoverageOverride',
  auditLog: 'organizationCoverageAuditLog',
}

function OrganizationEdit() {
  const { id } = useParams()
  const queryClient = useQueryClient()
  const { createNotification } = useNotification()

  const [formData, setFormData] = useState({
    orientation: '',
  })

  const { data: organizationData } = useQuery(['organization', id], () => getOrganization(id), {
    refetchOnWindowFocus: false,
  })
  const organizationName = organizationData?.data?.name || 'Loading...'
  const organizationStyles = organizationData?.data?.coverage?.styles || []

  const { data: coverageData } = useQuery(
    [QUERY_KEYS.coverage, id],
    () => getOrganizationCoverageOverride(id),
    {
      refetchOnWindowFocus: false,
    },
  )
  // When coverage data loads, update form state
  useEffect(() => {
    if (coverageData?.attributes) {
      setFormData({
        orientation: coverageData.attributes.orientation || '',
      })
    }
  }, [coverageData])

  const { mutate: saveCoverageOverride, isLoading: isSaving } = useMutation(
    coverage => saveOrganizationCoverageOverride({ organizationId: id, ...coverage }),
    {
      onSuccess: () => {
        createNotification({
          message: 'Coverage override updated successfully',
          variant: 'success',
        })
        queryClient.invalidateQueries([QUERY_KEYS.coverage, id])
        queryClient.invalidateQueries([QUERY_KEYS.auditLog, id])
      },
      onError: error => {
        createNotification({
          message: `Error updating coverage: ${error.message}`,
          variant: 'error',
        })
      },
    },
  )

  // Update local form state
  const handleCoverageChange = ({ target: { name, value } }) => {
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }))
  }

  // Save changes when update button clicked
  const handleSubmit = () => {
    saveCoverageOverride(formData)
  }

  return (
    <>
      <Typography variant="h4" sx={{ fontWeight: 'fontWeightBold' }}>
        Edit - {organizationName}
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={7}>
          <Select
            fullWidth
            label="Styles"
            value={organizationStyles}
            isMulti
            disabled
            options={Object.entries(COVERAGE_STYLES).map(([value, label]) => ({
              value,
              label,
            }))}
            sx={{ mb: 2 }}
          />

          <Select
            fullWidth
            label="Orientation"
            value={formData.orientation}
            name="orientation"
            onChange={handleCoverageChange}
            options={[
              { value: null, label: 'Default' },
              ...Object.entries(ORIENTATION_TYPES).map(([value, label]) => ({
                value,
                label,
              })),
            ]}
          />

          <Button color="primary" isLoading={isSaving} onClick={handleSubmit} sx={{ mt: 2 }}>
            Update
          </Button>
        </Grid>

        <Grid item xs={12}>
          <InternalUpdateLogList
            organizationId={id}
            auditFetchFunc={getOrganizationCoverageAuditLog}
            queryKey={QUERY_KEYS.auditLog}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default OrganizationEdit
