import Jsona, { SwitchCaseJsonMapper } from 'jsona'
import { irwin } from 'config'
import { httpClient } from './httpClient'
import { camelCase } from 'change-case-object'

const DEFAULT_CUSTOMER_AFFILIATIONS_URL = 'v2/customer_affiliations'

const deserializer = new Jsona({
  jsonPropertiesMapper: new SwitchCaseJsonMapper({ switchChar: '_' }),
})

function client() {
  return httpClient(irwin.baseUrl)
}

export async function getCustomerAffiliations(params = {}) {
  const response = await client().get(DEFAULT_CUSTOMER_AFFILIATIONS_URL, { params: params })

  return {
    data: deserializer.deserialize(response),
    total: camelCase(response.meta.total || 0),
    links: response.links,
  }
}

export function getCustomerAffiliationsCSVUrl(affiliatedId) {
  return `${irwin.baseUrl}/${DEFAULT_CUSTOMER_AFFILIATIONS_URL}.csv?affiliated_id=${affiliatedId}`
}
