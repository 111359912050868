import { client, deserializer } from 'services/client'
import { toCamel } from 'change-object-case'
import axios from 'axios'

/**
 * Upload file with presigned url
 * @param {object} args - arguments
 * @param {string} args.presignedUrl - presigned url to upload file to
 * @param {File} args.file - file to upload
 * @param {AbortSignal} args.signal - signal to cancel the request
 * @returns {object} - response object for checking status of upload request
 */
export const uploadFileWithPresignedUrl = async ({ presignedUrl, file, signal }) => {
  const response = await axios.put(presignedUrl, file, {
    signal,
    headers: {
      'Content-Type': file.type,
    },
  })

  return response
}

/**
 * Get presigned url for file upload
 * @param {File} files - files to upload
 * @param {string} bucketType - type of bucket to upload file to, possible values: 'public', 'private'
 * public bucket is used for publicly accessible files like email attachments and private bucket is used for files only viewable internally like irwin assistant uploads.
 * @param {AbortSignal} signal - signal to cancel the request
 * @returns {object} - presigned url and file key (uuid + file name)
 * Note: Even if we only need fileName we need to pass file here since we are using it in useMutation onSuccess
 */
export const getPresignedUrl = async (files, bucketType, signal) => {
  const response = await client().get(`/v2/file/presigned_url`, {
    signal,
    params: {
      bucket: bucketType,
      file_names: files.map(file => file.name),
    },
  })

  return toCamel(response)
}

/**
 * Get file status
 * @param {string} fileKeys - keys of the files to get statuses (uuid + file name) (returned from getPresignedUrl call)
 * @param {string} bucketType - type of bucket to upload file to, possible values: 'public', 'private'
 * public bucket is used for publicly accessible files like email attachments and private bucket is used for files only viewable internally like irwin assistant uploads.
 * @returns {object} - file status object
 */
export const getFileUploadStatus = async (fileKeys, bucketType) => {
  const response = await client().get('/v2/file/status', {
    params: {
      bucket: bucketType,
      file_keys: fileKeys,
    },
  })

  return toCamel(response)
}

export const sendNoboFileToQueue = async data => {
  const response = await client().post(`v2/ds6/nobo/reports`, data)
  return deserializer.deserialize(response)
}
