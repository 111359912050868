import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { DataGrid, GridToolbarQuickFilter } from '@the-platform-group/component-library/DataGrid'
import { Typography } from '@the-platform-group/component-library'
import { formatDate } from '@the-platform-group/formatters/dateFormat'
import focuses from 'constants/focus'
import ORGANIZATION_TYPES, { PARENT_ORGANIZATION_TYPE } from 'constants/organizationTypes'
import { COVERAGE_STYLES, ORIENTATION_TYPES } from 'constants/coverageStyles'
import COVERAGE_TURNOVER_LABELS from 'constants/coverageTurnoverLables'
import { toCurrencyMM } from '@the-platform-group/formatters/number'
import { DATETIME_WITH_TIMEZONE_FORMAT } from 'constants/formats'
import COUNTRIES from 'constants/countries'
import { titleCase } from 'change-object-case'
import useMarketCapsPresentational from 'hooks/marketCaps/useMarketCapsPresentational'

const ORGANIZATION_TYPES_WITH_GROUP = { ...ORGANIZATION_TYPES, ...PARENT_ORGANIZATION_TYPE }

/**
 * Displays a table of audit logs showing changes made to an organization's fields
 * @param {Object} props - Component props
 * @param {string} props.organizationId - ID of the organization to show audit logs for
 * @returns {React.Component} Table displaying audit log entries with field name, content, timestamp and user
 */
const InternalUpdateLogList = ({ organizationId, auditFetchFunc, queryKey }) => {
  const { getLabel: getMarketCapLabel } = useMarketCapsPresentational()
  const [pagination, setPagination] = useState({ page: 0, pageSize: 25 })

  const { data: auditLogsData, isLoading } = useQuery(
    [queryKey, organizationId],
    () => auditFetchFunc(organizationId),
    {
      enabled: Boolean(organizationId),
      select: data => {
        const formattedLogs = data.map(log => ({
          ...log,
          formattedField: titleCase(log.field).replace(/attributes/i, ' - '),
          formattedValue: formatAuditLogValue(log.value, log.field, getMarketCapLabel),
          formattedTime: formatDate(log.time, DATETIME_WITH_TIMEZONE_FORMAT),
        }))

        return {
          data: formattedLogs,
          total: formattedLogs.length,
        }
      },
    },
  )

  const handlePageChange = newPage => {
    setPagination(prevState => ({ ...prevState, page: newPage }))
  }

  const handleRowsChange = newPageSize => {
    setPagination(prevState => ({ ...prevState, page: 0, pageSize: newPageSize }))
  }

  const columns = [
    { field: 'formattedField', headerName: 'Field Name', flex: 1 },
    {
      field: 'formattedValue',
      headerName: 'Field Content',
      flex: 2,
      // Allow long text to wrap and display in full
      wrap: false,
      autoHeight: true,
      renderCell: params => (
        <Typography variant="body2" style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}>
          {params.value}
        </Typography>
      ),
    },
    { field: 'formattedTime', headerName: 'Updated At', flex: 1 },
    { field: 'user_name', headerName: 'Updated By', flex: 1 },
  ]

  const auditLogs = auditLogsData?.data || []

  return (
    <>
      <Typography variant="h6" sx={{ fontWeight: 'fontWeightBold', my: 2 }}>
        Internal Update History
      </Typography>
      <DataGrid
        rows={auditLogs}
        columns={columns}
        loading={isLoading}
        paginationMode="client"
        page={pagination.page}
        pageSize={pagination.pageSize}
        onPageChange={handlePageChange}
        onPageSizeChange={handleRowsChange}
        pageSizeOptions={[25, 50, 100]}
        disableColumnFilter
        disableColumnMenu
        getRowHeight={() => 'auto'}
        // These enable DataGrid native client side filter search on the table
        slots={{ toolbar: GridToolbarQuickFilter }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
      />
    </>
  )
}

/**
 * Formats audit log values based on their field type for display
 * @param {*} value - The value to format (can be string, array, or null)
 * @param {string} field - Field name indicating how to format the value (e.g. 'name', 'type', 'focuses')
 * @returns {string} Formatted string value for display
 */
const formatAuditLogValue = (value, field, getMarketCapLabel) => {
  if (!value) {
    return '-'
  }

  // Handle fields with individual custom formatting
  if (field === 'coverage_attributes_turnover') {
    return COVERAGE_TURNOVER_LABELS[value] || value
  }
  if (field === 'coverage_attributes_aum') {
    return `$${value} (${toCurrencyMM(value)})`
  }
  if (field === 'coverage_attributes_orientation' || field === 'orientation') {
    return ORIENTATION_TYPES[value] || value
  }
  if (field === 'type') {
    return ORGANIZATION_TYPES_WITH_GROUP[value] || value
  }
  if (field === 'approved_at') {
    return formatDate(value, DATETIME_WITH_TIMEZONE_FORMAT)
  }

  // For fields with array mappers, process them in one go
  const mapper = arrayFieldMappers[field]
  if (mapper) {
    return mapArrayValues(value, mapper, getMarketCapLabel)
  }

  // Fallback: return original value
  return value
}

// Helper to map array values if a mapper exists, else join raw values
const mapArrayValues = (value, mapper, getMarketCapLabel) =>
  Array.isArray(value)
    ? value.map(item => (mapper ? mapper(item, getMarketCapLabel) : item)).join(', ')
    : value

// Lookup for fields that require array mapping
const arrayFieldMappers = {
  coverage_attributes_styles: style => COVERAGE_STYLES[style] || style,
  focuses_attributes_sector: value => focuses.SECTORS[value] || value,
  focuses_attributes_geo_focus: value => COUNTRIES[value] || value,
  focuses_attributes_subsector: value => focuses.SUBSECTORS[value] || value,
  focuses_attributes_market_cap: (value, getMarketCapLabel) => getMarketCapLabel(value) || value,
}

export default InternalUpdateLogList
