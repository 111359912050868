import React from 'react'
import { Grid, Typography, CircularProgress } from '@the-platform-group/component-library'
import {
  FileCopy as FileCopyIcon,
  Error as ErrorIcon,
  CheckCircle as CheckCircleIcon,
} from '@the-platform-group/component-library/Icons'

/**
 * Modal to display the status of file uploads which is uploading with presigned url and virus scanning
 * @param {object} props - component props
 * @param {boolean} props.isSuccess - boolean to indicate if file upload is successful
 * @param {string} props.error - error message if file upload fails
 * @param {string} props.fileName - name of the file being uploaded
 * @returns {React.Component} - modal to display the status of file uploads
 */
const FileUploadStatus = ({ isSuccess, error, fileName }) => {
  const hasSettled = isSuccess || error

  return (
    <Grid item container>
      <Grid item xs={1}>
        <FileCopyIcon
          sx={{
            color: 'secondary.dark',
          }}
        />
      </Grid>
      <Grid item xs={10}>
        <Typography variant="body1" color="secondary.dark">
          {fileName || 'File'}
        </Typography>
        {!hasSettled && (
          <Typography variant="caption" color="secondary.dark">
            Scanning for viruses
          </Typography>
        )}
        {error && (
          <Typography variant="caption" color="error.main">
            {error}
          </Typography>
        )}
        {isSuccess && (
          <Typography variant="caption" color="success.main">
            File has been uploaded successfully.
          </Typography>
        )}
      </Grid>
      <Grid item xs={1} sx={{ p: 1 }}>
        {error && (
          <ErrorIcon
            size={20}
            sx={{
              color: 'error.main',
            }}
          />
        )}
        {isSuccess && (
          <CheckCircleIcon
            size={20}
            sx={{
              color: 'success.main',
            }}
          />
        )}
        {!hasSettled && <CircularProgress size={20} />}
      </Grid>
    </Grid>
  )
}

export default FileUploadStatus
