import React from 'react'
import { Box, Paper, Grid, Typography } from '@the-platform-group/component-library'
import FileUploadStatus from './FileUploadStatus'

/**
 * Modal to display the status of file uploads which is uploading with presigned url and virus scanning
 *
 * @param {object} props - component props
 * @param {object} props.files - collection of files to be uploaded
 * @returns {React.Component} - modal to display the status of file uploads
 */
const FileUploadStatusModal = ({ files = {} }) => {
  return (
    <Box
      sx={{
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 3,
        width: '100%',
        height: '100%',
      }}
    >
      <Paper elevation={3} sx={{ py: 2, px: 3, minWidth: '50%' }}>
        <Grid container spacing={2}>
          <Grid item container alignItems="center">
            <Grid item xs={11}>
              <Typography fontWeight="bold">Uploading File ...</Typography>
            </Grid>
          </Grid>
          {Object.values(files).map(file => (
            <FileUploadStatus key={file.fileName} {...file} />
          ))}
        </Grid>
      </Paper>
    </Box>
  )
}

export default FileUploadStatusModal
