import RollbarRaw from 'rollbar'

const rollbarEnv = import.meta.env.MODE
const version = import.meta.env.VITE_VERSION
const rollbarConfig = {
  accessToken: '38901a6d3ca0445c9065beacf86e53be',
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled:
    window.location.hostname !== 'localhost' &&
    (rollbarEnv === 'production' || rollbarEnv === 'staging'),
  payload: {
    environment: rollbarEnv,
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: `${version}`,
        // ---------------------------------------
        // Optionally have Rollbar guess which frames the error was
        // thrown from when the browser does not provide line
        // and column numbers.
        guess_uncaught_frames: true,
      },
    },
  },
}
const Rollbar = new RollbarRaw(rollbarConfig)

export default Rollbar
